export const CLEAR_LAST_MAXIMISED = 'CLEAR_LAST_MAXIMISED';
export const GETTING_MORE_KIMONOS = 'GETTING_MORE_KIMONOS';
export const GET_KIMONOS_FAILURE = 'GET_KIMONOS_FAILURE';
export const GET_KIMONOS_SUCCESS = 'GET_KIMONOS_SUCCESS';
export const GOT_ALL_KIMONOS = 'GOT_ALL_KIMONOS';
export const HOME_LOADED = 'HOME_LOADED';
export const INITIALISED_GOOGLE_ANALYTICS = 'INITIALISED_GOOGLE_ANALYTICS';
export const KIMONO_LOADED = 'KIMONO_LOADED';
export const MAXIMISE_KIMONO = 'MAXIMISE_KIMONO';
export const MINIMISE_GALLERY = 'MINIMISE_GALLERY';
export const MINIMISE_KIMONO = 'MINIMISE_KIMONO';
export const SAVE_FINISHED_IMAGE = 'SAVE_FINISHED_IMAGE';
export const SET_MAX_SCALE = 'SET_MAX_SCALE';
export const SUBMITTING_KIMONO = 'SUBMITTING_KIMONO';
export const SUBMIT_KIMONO_FAILURE = 'SUBMIT_KIMONO_FAILURE';
export const SUBMIT_KIMONO_SUCCESS = 'SUBMIT_KIMONO_SUCCESS';
export const USER_FINISHED_DESIGNING = 'USER_FINISHED_DESIGNING';
export const USER_UPLOADED_IMAGE = 'USER_UPLOADED_IMAGE';
export const USER_LEFT_DESIGN_PAGE = 'USER_LEFT_DESIGN_PAGE';
export const USER_LEFT_HOME_PAGE = 'USER_LEFT_HOME_PAGE';
export const UPDATE_BACKGROUND_GRADIENT = 'UPDATE_BACKGROUND_GRADIENT';
export const UPDATE_HANDLE = 'UPDATE_HANDLE';
export const UPDATE_NAME = 'UPDATE_NAME';
export const UPDATE_SCROLL_DOWN = 'UPDATE_SCROLL_DOWN';
export const UPDATE_TITLE = 'UPDATE_TITLE';
